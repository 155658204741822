import { graphql } from "gatsby";
import * as StocksIconsData from "../../../pageData/DemoContestAllData/StocksIconsData.json";

const query = graphql`
  query StocksIconsQuery {
    allPrismicStocksIcons {
      nodes {
        data {
          us30_icon_heading {
            text
          }
          us30_icon{
            url
          }
          stocks_icons_box {            
            stocks_icons_images {
              url
            }
            stocks_icons_heading {
              text
            }
          }
        }
        lang
      }
    }
  }
`;

const dataResolver = (response, lang) => {
  const { allPrismicStocksIcons } = response;
  return allPrismicStocksIcons.nodes.find((node) => node.lang === lang).data;
};

const useData = (lang) => {
  // debugger;
  // const response = useStaticQuery(query);
  // return dataResolver(response, lang);
  return dataResolver(StocksIconsData, lang);
};

export default useData;
