import { graphql } from "gatsby";
import * as DemoContestData from "../../../pageData/DemoContestAllData/DemoContestData.json";

const query = graphql`
  query DemoContestQuery {
    allPrismicDemoContest {
      nodes {
        data {
          demo_side_image {
            url
          }
          demo_heading {
            text
          }
          demo_content {
            text
          }
          demo_link {
            text
          }
          demo_link_text {
            text
          }
        }
        lang
      }
    }
  }
`;

const dataResolver = (response, lang) => {
  const { allPrismicDemoContest } = response;
  return allPrismicDemoContest.nodes.find((node) => node.lang === lang).data;
};

const useData = (lang) => {
  // debugger;
  // const response = useStaticQuery(query);
  // return dataResolver(response, lang);
  return dataResolver(DemoContestData, lang);
};

export default useData;
