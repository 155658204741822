import { graphql } from "gatsby";
import * as DemoTermsData from "../../../pageData/DemoContestAllData/DemoTermsData.json";

const query = graphql`
  query DemoTermsQuery {
    allPrismicDemoTerms {
      nodes {
        data {
          DemoTerms_content {
            text
          }
        }
        lang
      }
    }
  }
`;

const dataResolver = (response, lang) => {
  const { allPrismicDemoTerms } = response;
  return allPrismicDemoTerms.nodes.find((node) => node.lang === lang).data;
};

const useData = (lang) => {
  // debugger;
  // const response = useStaticQuery(query);
  // return dataResolver(response, lang);
  return dataResolver(DemoTermsData, lang);
};

export default useData;
